import React, { useEffect, useState } from "react"
import { Link } from "react-scroll"
import PropTypes from "prop-types"

import AniLink from "./AniLink"

import springkrafLogo from "../assets/images/springkraf-logo.png"
import springkrafLogoLight from "../assets/images/springkraf-logo-light.png"

function Header(siteTitle) {
  let lastScrollY = 0
  const [shrunkState, setShrunkState] = useState(false)
  const [navStatus, setNavStatus] = useState(false)

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, true)
  }, [])

  function handleScroll() {
    lastScrollY = window.scrollY
    if (lastScrollY > 50) {
      setShrunkState(true)
    } else {
      setShrunkState(false)
    }
  }

  function handleBurgerClick() {
    setNavStatus(!navStatus)
  }

  return (
    <header>
      <div className={"header" + (shrunkState ? " header-scrolled" : "")}>
        <div className="container nav">
          <AniLink to="/">
            <img src={springkrafLogo} style={{ width: 180 }} />
          </AniLink>
          <div className="nav-items">
            <ul>
              <li className="nav-item">
                <AniLink to="/services">Services</AniLink>
              </li>
              <li className="nav-item">
                <AniLink to="/how-we-work">How We Work</AniLink>
              </li>
              <li className="nav-item">
                <AniLink to="/portfolio">Portfolio</AniLink>
              </li>
              <li className="nav-item">
                <AniLink to="/company">Company</AniLink>
              </li>
              <li className="nav-item">
                <Link to="contact" smooth={true} offset={-80}>
                  <div className="button button-primary button-small">
                    Contact Us
                  </div>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div
        className={"header-mobile" + (shrunkState ? " header-scrolled" : "")}
      >
        <ul className="header-content">
          <li
            className={"header-burger" + (navStatus === true ? " is-open" : "")}
            onClick={() => handleBurgerClick()}
          >
            <span />
            <span />
          </li>
          <li className="header-logo-container">
            <img src={springkrafLogoLight} style={{ width: 180, height: 32 }} />
          </li>
        </ul>
        <ul
          className={
            "header-navigation" + (navStatus === true ? " is-open" : "")
          }
        >
          <li>
            <AniLink onClick={() => handleBurgerClick()} to="/">
              Home
            </AniLink>
          </li>
          <li>
            <AniLink onClick={() => handleBurgerClick()} to="/services">
              Services
            </AniLink>
          </li>
          <li>
            <AniLink onClick={() => handleBurgerClick()} to="/how-we-work">
              How We Work
            </AniLink>
          </li>
          <li>
            <AniLink onClick={() => handleBurgerClick()} to="/portfolio">
              Portfolio
            </AniLink>
          </li>
          <li>
            <AniLink onClick={() => handleBurgerClick()} to="/company">
              Company
            </AniLink>
          </li>
          <li>
            <AniLink onClick={() => handleBurgerClick()} to="/company">
              Contact
            </AniLink>
          </li>
        </ul>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
