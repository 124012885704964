// Services logo
import logoCloudServices from "../images/services/cloud-services.svg";
import logoDataCapture from "../images/services/data-capture.svg";
import logoMaintainanceContinuation from "../images/services/maintainance-continuation.svg";
import logoMobileDevelopment from "../images/services/mobile-development.svg";
import logoProjectManagement from "../images/services/project-management.svg";
import logoQualityAssurance from "../images/services/quality-assurance.svg";
import logoStrategicConsulting from "../images/services/strategic-consulting.svg";
import logoUiUxRetouch from "../images/services/ui-ux-retouch.svg";
import logoWebsiteDevelopment from "../images/services/website-development.svg";

import logoCommerce from '../images/company/commerce.svg'
import logoCreative from '../images/company/creative.svg'
import logoHr from '../images/company/hr.svg'
import logoRealEstate from '../images/company/real-estate.svg'
import logoRemittance from '../images/company/remittance.svg'
import logoTax from '../images/company/tax.svg'
import logoSalon from '../images/company/salon.svg'
import logoCommunity from '../images/company/community.svg'

export const services = [
  {
    id: "strategic-consulting",
    title: "Strategic Consulting",
    subtitle: "Turn ideas into reality",
    description:
      "Before starting the process of development or re-architecture, we will provide strategic consultation in terms of code, the technology used, timeline, total cost, and capability of the solutions provided to sustain business needs.",
    image: logoStrategicConsulting,
    link: "/services#strategic-consulting",
  },
  {
    id: "ui-ux-retouch",
    title: "UI/UX Retouch",
    subtitle:
      "Always easy and beautiful",
    description:
      "Making UI UX an essential part of our service starts from building prototypes to finished products. By working together with your team at the time of discussion, conclude, and make it a product that is easy and graceful to use.",
    image: logoUiUxRetouch,
    link: "/services#ui-ux-retouch",
  },
  {
    id: "cloud-services",
    title: "Cloud Services",
    subtitle: "Scaling the infrastructure",
    description:
      "Using cloud technology for your products makes your business more reliable and scalable. Delivering great cloud design patterns and strategies to maximize the benefits of using cloud solutions to you.",
    image: logoCloudServices,
    link: "/services#cloud-services",
  },
  {
    id: "project-management",
    title: "Project Management",
    subtitle: "Schedule, delegate, and tracking",
    description:
      "We use an agile software development method, and this will significantly help you to get product previews faster, flexibility in adding features, and stakeholders can progressively prioritize things reflecting actual market conditions at a given time",
    image: logoProjectManagement,
    link: "/services#project-management",
  },
  {
    id: "web-development",
    title: "Web Development",
    subtitle: "Reach users across platforms",
    description:
      "Web development provides the option to expand the business scope from cellular devices to computers. We provide web development with responsive displays for various screens to complement your business.",
    image: logoWebsiteDevelopment,
    link: "/services#web-development",
  },
  {
    id: "mobile-development",
    title: "Mobile Development",
    subtitle: "Better experience",
    description:
      "We know exactly how to provide an excellent experience through the application to be held by your customers. Help you choose which development is better suited to your business needs, both native or hybrid.",
    image: logoMobileDevelopment,
    link: "/services#mobile-development",
  },
  {
    id: "quality-assurance",
    title: "Quality Assurance",
    subtitle: "Bringing quality",
    description:
      "We bring a gradual experiment from the developer, team leader, and QA. Minimize the possibility of unwanted problems occurring during production.",
    image: logoQualityAssurance,
    link: "/services#quality-assurance",
  },
  {
    id: "data-capture",
    title: "Data Capture",
    subtitle: "Decision by data",
    description:
      "We are helping business continuity and development by analyzing data, helping you make decisions more wisely by not guessing, and doing market speculation.",
    image: logoDataCapture,
    link: "/services#data-capture",
  },
  {
    id: "maintainance-continuation",
    title: "Maintainance & Continuation",
    subtitle: "No more worries",
    description:
      "We are a professional and committed team. We will always maintain and update our products to support your business. You can focus on business and fully entrust this section to us.",
    image: logoMaintainanceContinuation,
    link: "/services#maintainance-continuation",
  },
];

export const portfolioSchema1 = [
  {
    id: "strategic-consulting",
    title: "Strategic Consulting",
    subtitle: "Turn ideas into reality",
    description:
      "Before starting the process of development or re-architecture, we will provide strategic consultation in terms of code, the technology used, timeline, total cost, and capability of the solutions provided to sustain business needs.",
    image: logoStrategicConsulting,
    link: "/services#strategic-consulting",
  },
  {
    id: "ui-ux-retouch",
    title: "UI/UX Retouch",
    subtitle:
      "Always easy and beautiful",
    description:
      "Making UI UX an essential part of our service starts from building prototypes to finished products. By working together with your team at the time of discussion, conclude, and make it a product that is easy and graceful to use.",
    image: logoUiUxRetouch,
    link: "/services#ui-ux-retouch",
  },
  {
    id: "web-development",
    title: "Web Development",
    subtitle: "Reach users across platforms",
    description:
      "Web development provides the option to expand the business scope from cellular devices to computers. We provide web development with responsive displays for various screens to complement your business.",
    image: logoWebsiteDevelopment,
    link: "/services#web-development",
  },
  {
    id: "mobile-development",
    title: "Mobile Development",
    subtitle: "Better experience",
    description:
      "We know exactly how to provide an excellent experience through the application to be held by your customers. Help you choose which development is better suited to your business needs, both native or hybrid.",
    image: logoMobileDevelopment,
    link: "/services#mobile-development",
  },
  {
    id: "quality-assurance",
    title: "Quality Assurance",
    subtitle: "Bringing quality",
    description:
      "We bring a gradual experiment from the developer, team leader, and QA. Minimize the possibility of unwanted problems occurring during production.",
    image: logoQualityAssurance,
    link: "/services#quality-assurance",
  },
  {
    id: "maintainance-continuation",
    title: "Maintainance & Continuation",
    subtitle: "No more worries",
    description:
      "We are a professional and committed team. We will always maintain and update our products to support your business. You can focus on business and fully entrust this section to us.",
    image: logoMaintainanceContinuation,
    link: "/services#maintainance-continuation",
  },
];

export const portfolioSchema2 = [
  {
    id: "strategic-consulting",
    title: "Strategic Consulting",
    subtitle: "Turn ideas into reality",
    description:
      "Before starting the process of development or re-architecture, we will provide strategic consultation in terms of code, the technology used, timeline, total cost, and capability of the solutions provided to sustain business needs.",
    image: logoStrategicConsulting,
    link: "/services#strategic-consulting",
  },
  {
    id: "ui-ux-retouch",
    title: "UI/UX Retouch",
    subtitle:
      "Always easy and beautiful",
    description:
      "Making UI UX an essential part of our service starts from building prototypes to finished products. By working together with your team at the time of discussion, conclude, and make it a product that is easy and graceful to use.",
    image: logoUiUxRetouch,
    link: "/services#ui-ux-retouch",
  },
  {
    id: "web-development",
    title: "Web Development",
    subtitle: "Reach users across platforms",
    description:
      "Web development provides the option to expand the business scope from cellular devices to computers. We provide web development with responsive displays for various screens to complement your business.",
    image: logoWebsiteDevelopment,
    link: "/services#web-development",
  },
  {
    id: "mobile-development",
    title: "Mobile Development",
    subtitle: "Better experience",
    description:
      "We know exactly how to provide an excellent experience through the application to be held by your customers. Help you choose which development is better suited to your business needs, both native or hybrid.",
    image: logoMobileDevelopment,
    link: "/services#mobile-development",
  },
  {
    id: "quality-assurance",
    title: "Quality Assurance",
    subtitle: "Bringing quality",
    description:
      "We bring a gradual experiment from the developer, team leader, and QA. Minimize the possibility of unwanted problems occurring during production.",
    image: logoQualityAssurance,
    link: "/services#quality-assurance",
  },
];

export const companyClient = [
  {
    title: "Real Estate",
    image: logoRealEstate,
  },
  {
    title: "Remittance",
    image: logoRemittance,
  },
  {
    title: "Commerce",
    image: logoCommerce,
  },
  {
    title: "Tax",
    image: logoTax,
  },
  {
    title: "HR",
    image: logoHr,
  },
  {
    title: "Creative",
    image: logoCreative,
  },
  {
    title: "Salon",
    image: logoSalon,
  },
  {
    title: "Reseller Community",
    image: logoCommunity,
  },

]
