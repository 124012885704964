import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"

function Link({ to, children, className = '' }) {
  return (
    <>
      <AniLink
        to={to}
        cover
        bg="#F2FFF3"
        direction="right"
        className={className}
        duration={1.5}
      >
        {children}
      </AniLink>
      {/* <AniLink to={to} paintDrip hex="#F2FFF3" className={className}>
        {children}
      </AniLink> */}
      {/* <AniLink to={to} swipe direction="down" bg="#F2FFF3" className={className}>
        {children}
      </AniLink> */}
      {/* <AniLink to={to} morph bg="#F2FFF3" className={className}>
        {children}
      </AniLink> */}
    </>
  )
}

export default Link
